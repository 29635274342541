<template>
  <div class="info-search-form__right-container">
    <div v-if="allCompanies.length == 0 && !loader" class="no-content-template">
      <div v-if="showGsTemplate" class="no-content-template__modal">
        <GS1BtnIcon
          class="modal__close-button"
          color="#A7ACB1"
          transparent
          size="default"
          :icon="['close', 'default']"
          @click="onCloseGsTemplate"
        ></GS1BtnIcon>
        <div class="modal__icon-container">
          <GS1Icon class="description__active-icon" tiny color="#7AC143" icon="success"></GS1Icon>
          <h2 class="display-large font-wbold font-sf-display gs-no-margin no-content-template__modal__header-text">Verified by GS1</h2>
        </div>

        <span class="display-large font-gray no-content-template__modal__main-text">Информация по товарам от производителей всего мира</span>
        <span class="active-icon__text font-gray display-secondary no-content-template__modal__footer-text">
          Данные предоставлены напрямую производителями
        </span>
      </div>
      <div class="modal__text-container">
        <h2 class="main-text__no-content display-medium font-w-b modal__text-container__header-text">Еще нет результатов поиска</h2>
        <span class="font-gray display-small modal__text-container__main-text">{{ templateDescription }}</span>
      </div>
    </div>
    <GS1Loader v-if="loader" :note-value="loaderDescription"></GS1Loader>
    <div v-if="allCompanies.length > 0 && !loader" class="info-search-form__table-container">
      <GS1TableFlat
        v-model:search="searchString"
        v-bind="{
          fields: tableFields,
          data: companies,
          displayedCount: companies.length,
          totalCount: companies.length,
          linkable: true,
          showRange: false,
          showHeader: true,
          showFooter: false,
          rowActiveField: `${curentActiveVField}`,
          rowActive: [currentValue],
        }"
        @record-click="openCompany"
      >
        <template #title>
          <div class="info-search-form__table__heading">{{ tableTitle }}</div>
        </template>
        <template #header>
          <GS1Tabs class="info-search-form__header-filter__container">
            <GS1TabsItem :active="resultViewType === 0" @click="setResultViewType(0)">Все</GS1TabsItem>
            <GS1TabsItem :active="resultViewType === 1" @click="setResultViewType(1)">Успешные</GS1TabsItem>
            <GS1TabsItem :active="resultViewType === 2" @click="setResultViewType(2)">С ошибками</GS1TabsItem>
          </GS1Tabs>
        </template>
        <template #header-right>
          <div class="info-search-form__table-btn__container">
            <div class="info-search-form__table-download-btn__container">
              <GS1Btn success class="info-search-form__table-download-btn" no-margin @click="exportCompanies(true)">
                Скачать все
                <template #left>
                  <GS1Icon icon="download"></GS1Icon>
                </template>
              </GS1Btn>
            </div>
          </div>
        </template>
        <template #after-header>
          <div v-if="branchCount > 0" class="no-content-template__modal company-search__branch-count">
            <span class="modal__icon-container no-content-template__modal__footer-text">
              По данным ИНН есть еще {{ branchCount }} дочерних организаций
            </span>
          </div>
        </template>
        <template #label-actions>
          <GS1InputMultiCheck v-model="selectAll" no-margin></GS1InputMultiCheck>
        </template>
        <template #value="slot">
          <span :class="{ error: slot.item.hasError }">{{ slot.item[searchKey.toLowerCase()] }}</span>
        </template>
        <template #actions="slot">
          <GS1InputCheck v-model="selection[slot.item[searchKey.toLowerCase()]]" no-margin></GS1InputCheck>
        </template>
        <template #description="slot">
          <div class="description__text">
            <div v-if="!slot.item.hasError" class="description__active-icon description__active-icon--success"></div>
            <div v-if="slot.item.hasError" class="description__active-icon"></div>
            <div v-if="!slot.item.hasError" class="description__text-container">
              {{ slot.item.name }}
              <div v-if="slot.item?.errorNote" class="error-note">{{ slot.item?.errorNote }}</div>
            </div>
            <div v-if="slot.item.hasError" class="">
              <div class="name">
                {{ slot.item.error }}
              </div>
              <div v-if="slot.item?.errorNote" class="error-note">
                {{ slot.item?.errorNote }}
                <GS1Icon
                  v-if="slot.item?.errorExtraNote?.length > 0"
                  v-gs1-tooltip.right="slot.item?.errorExtraNote"
                  icon="info-circle"
                  class="info-search-form__extra-info-icon"
                ></GS1Icon>
              </div>
            </div>
          </div>
        </template>
      </GS1TableFlat>
      <GS1ItemsSelector
        class="company-search__bottom-items-selector"
        v-bind="{
          total: companies.length,
          selected: selected.length,
          active: selected.length > 0,
          showCancelBtn: false,
        }"
      >
        <GS1BtnIcon
          v-if="selected.length > 0"
          :icon="['download']"
          open-above
          success
          no-margin
          class="info-search-form__table-download-btn green"
          @click="exportCompanies()"
        ></GS1BtnIcon>
        <GS1Btn accent no-margin @click="uncheckAll">
          <template #left>
            <GS1Icon icon="close-square"></GS1Icon>
          </template>
          Снять выбор
        </GS1Btn>
      </GS1ItemsSelector>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted, watch, ref } from 'vue';
import type { ComputedRef, Ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import User from '@/modules/auth/models/User';
import type { GS1TableField } from '@gs1ru/web-components';

export default defineComponent({
  name: 'CompanyResults',
  props: {
    searchKey: {
      default: '',
      type: String,
    },
    templateDescription: {
      default: 'Здесь будут результаты поиска по введенным ключам',
      type: String,
    },
    loaderDescription: {
      default: 'Загружаем результаты поиска по введенным ключам',
      type: String,
    },
    tableTitle: {
      default: 'Результаты поиска по ключам',
      type: String,
    },
    tableColumnTitle: {
      default: 'Выбранный ключ',
      type: String,
    },
  },
  emits: ['check'],
  setup(props: any) {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    /**
     * Чекбокс "Выбрать все"
     */
    const selectAll: Ref<number> = ref(0);

    /**
     * Окно с информацией
     */
    const infoModal: Ref<any> = ref(null);

    /**
     * Активность окна с информацией
     */
    const activeInfo: Ref<boolean> = ref(false);

    /**
     * Текущая страница
     */
    const page: Ref<number> = ref(1);

    /**
     * Кол-во элементов на страницу
     */
    const perPage: Ref<number> = ref(10);

    /**
     * Поисковой запрос
     */
    const searchString: Ref<string> = ref('');

    // Переменная для фильтрации (Успешные, с ошибкой, все)
    const resultViewType: Ref<number> = ref(0);

    /**
     * Элемент выбран
     */
    const selection: Ref<Record<string, boolean>> = ref({});

    const selected = computed(() => Object.values(selection.value).filter((i: boolean) => i));

    const curentActiveVField = computed(() => {
      if (props.searchKey.toLowerCase() == 'inn') return 'index';
      else return props.searchKey.toLowerCase();
    });

    /**
     * Объект авторизованного пользователя
     */
    const user: ComputedRef<User> = computed(() => store.state.auth.user);

    const tableFields: ComputedRef<Array<GS1TableField>> = computed(() => [
      { label: '', field: 'actions', sort: false, width: '35px', drag: false, fixed: true },
      {
        label: 'Описание',
        field: 'description',
        sort: false,
        lineClamp: 1,
      },
      {
        label: props.tableColumnTitle,
        field: 'value',
        sort: false,
        width: '150px',
        lineClamp: 1,
        fixed: true,
      },
    ]);

    const companyKeys: Ref<Record<string, string>[]> = ref([
      { value: 'GIAI', description: 'Global Individual Asset Identifier, Глобальный идентификатор узла, до 30 алфавитно-цифровых символов' },
      { value: 'SSCC', description: 'Serial Shipping Container Code, Серийный код транспортной упаковки,18 цифр' },
      { value: 'GSIN', description: 'Global Shipment Identification Number, глобальный идентификационный номер отправки груза, 17 цифр' },
      {
        value: 'GINC',
        description: 'Global Identification Number for Consignment, глобальный идентификатор номера товара, до 30 алфавитно-цифровых символов',
      },
      { value: 'GRAI', description: 'Global Returnable Asset Identifier, Глобальный номер возвратного актива, до 30 алфавитно-цифровых символов' },
      { value: 'GSRN', description: 'Global Service Relation Number, Глобальный номер услуг, 18 цифр' },
      { value: 'GDTI', description: 'Global Document Type Identifier, Глобальный Номер Документа, до 30 алфавитно-цифровых символов' },
      { value: 'GCN', description: 'Global Coupon Number, Глобальный Номер Купона, до 25 цифр' },
      { value: 'CPID', description: 'Component/Part Identifier, Номер Компонента/Детали, до 30 алфавитно-цифровых символов' },
      { value: 'GMN', description: 'Global Model Number, Глобальный Номер Модели до 25 алфавитно-цифровых символов' },
    ]);

    /**
     * Массив найденных компаний
     */
    const allCompanies: ComputedRef<Record<string, any>> = computed(() => {
      return store.state.companySearch.companies;
    });

    /**
     * Кол-во филиалов
     */
    const branchCount: ComputedRef<Record<string, any>> = computed(() => {
      return store.state.companySearch.branchCount;
    });

    /**
     * Массив найденных компаний с постраничной навигацией
     */
    const companies: ComputedRef<Record<string, any>> = computed(() => {
      let filteredCompanies = allCompanies.value.filter(
        (company: Record<string, any>) =>
          !searchString.value ||
          company?.gs1Licence?.licenseeName?.toLowerCase().includes(searchString.value.toLowerCase()) ||
          company?.name?.toLowerCase().includes(searchString.value.toLowerCase()) ||
          company[props.searchKey.toLowerCase()]?.includes(searchString.value.toLowerCase())
      );

      filteredCompanies = filteredCompanies.map((company: Record<string, any>) => {
        company.show = false;

        // Добавляем новое поле `name`
        if (props.searchKey !== 'gln') {
          company.name = company.gs1Licence?.licenseeName || '';
        } else {
          switch (company.glnRecords?.at(0)?.glnType) {
            case 'FIXED_PHYSICAL_LOCATION':
            case 'MOBILE_PHYSICAL_LOCATION':
              company.name =
                company.glnRecords?.at(0)?.physicalLocationName?.find((name: { language: string; value: string }) => name.language === 'ru')?.value ||
                'Неизвестно';
              break;
            case 'DIGITAL_LOCATION':
              company.name =
                company.glnRecords?.at(0)?.digitalLocationName?.find((name: { language: string; value: string }) => name.language === 'ru')?.value ||
                'Неизвестно';
              break;
            case 'LEGAL_ENTITY':
              company.name =
                company.glnRecords?.at(0)?.organizationName?.find((name: { language: string; value: string }) => name.language === 'ru')?.value ||
                'Неизвестно';
              break;
            case 'FUNCTION':
              company.name =
                company.glnRecords?.at(0)?.department?.find((dept: { language: string; value: string }) => dept.language === 'ru')?.value ||
                'Неизвестно';
              break;
            default:
              company.name = company.gs1Licence?.licenseeName || '';
          }
        }

        return company;
      });

      if (resultViewType.value) {
        filteredCompanies = filteredCompanies.filter((company: Record<string, any>) => {
          if (resultViewType.value === 1) return !company.hasError;
          else if (resultViewType.value === 2) return !!company.hasError;
        });
      }

      return filteredCompanies.map((company: Record<string, any>) => {
        const classes = [];

        if (typeof company.gln !== 'undefined') {
          classes.push('gln-' + company.gln);
        }

        if (company.hasError) {
          classes.push('has-error');
        }

        if (company.glnRecordStatus == 'DISCONTINUED') {
          classes.push('no-info');
        }

        company.classes = classes.join(' ');

        return company;
      });
    });

    /**
     * Флаг отображения результата
     */
    const showCompanyResults: ComputedRef<boolean> = computed(() => store.state.companySearch.showCompanyResults);

    /**
     * Флаги загрузки
     */
    const loading: ComputedRef<any> = computed(() => store.state.loading);

    /**
     * Состояние индикатора загрузки
     */
    const loader: Ref<boolean> = ref(false);

    const currentValue = computed(() => route.params.keyValue || '');

    /**
     * Показать шаблон GS1 для правого меню
     */
    const showGsTemplate: Ref<boolean> = ref(true);

    const companiesToExport: ComputedRef<Array<string>> = computed(
      () =>
        Object.entries(selection.value)
          .filter((element: [string, boolean]) => element[1])
          ?.map((element: [string, boolean]) => element[0]) || []
    );

    /**
     * Включение прелоадера
     */
    const showLoader = (): void => {
      loader.value = true;
    };

    /**
     * Включение прелоадера
     */
    const hideLoader = (): void => {
      loader.value = false;
    };

    /**
     * Экспорт списка компаний
     *
     * @param all Скачать все результаты, а не только выбранные
     */
    const exportCompanies = (all = false): void => {
      let values = [];

      if (all) {
        values = companies.value.map((company: Record<string, any>) => company[props.searchKey.toLowerCase()]);
      } else {
        values = companiesToExport.value;
      }

      store
        .dispatch('companySearch/export', {
          searchValues: values,
          searchKey: props.searchKey,
        })
        .then(() => {
          store.commit('setOverlay', false);
        });
    };

    /**
     * Скрытие плашки GS1
     */
    const onCloseGsTemplate = (): void => {
      showGsTemplate.value = false;
    };

    /**
     * Название роута для отображения карточки компании
     */
    const resultRouteName: ComputedRef<string> = computed(() => {
      switch (props.searchKey) {
        case 'inn':
        case 'gln':
          return 'company-search-by-' + props.searchKey + '-result';
        default:
          return 'company-search-by-key-result';
      }
    });

    /**
     * Отображения карточки компании
     *
     * @param e
     * @param company
     */
    const openCompany = (e: Event, company: any, index: number) => {
      if (
        !company ||
        (props.searchKey != 'inn' && company?.hasError && typeof company.gs1Licence === 'undefined') ||
        (props.searchKey == 'inn' && typeof company.status === 'undefined')
      ) {
        return;
      }

      const params: Record<string, string | number> = {};

      switch (props.searchKey) {
        case 'inn':
          params.keyValue = index;
          params.index = company.index;
          break;
        case 'gln':
        default:
          params.keyValue = company[props.searchKey.toLowerCase()];
      }

      if (!['inn', 'gln'].includes(props.searchKey)) {
        params.key = props.searchKey.toLowerCase();
      }

      router.push({
        name: resultRouteName.value,
        params: params,
      });
    };

    const setResultViewType = (value: number) => {
      resultViewType.value = value;
    };

    // Убрать выделение со всех элементов поиска
    const uncheckAll = () => {
      selection.value = {};
    };

    /**
     * Сброс состояния результатов
     */
    const reset = () => {
      loader.value = false;
      searchString.value = '';
      store.commit('companySearch/setCompanies', []);
    };

    watch(
      () => searchString,
      () => {
        selection.value = {};
      }
    );
    /**
     * Логика выбора элементов
     */
    watch(
      () => selectAll.value,
      () => {
        if (selectAll.value == 0) {
          uncheckAll();
        } else if (selectAll.value == 1) {
          companies.value.forEach((company: Record<string, any>) => {
            selection.value[company[props.searchKey.toLowerCase()]] = true;
          });
        }
      }
    );
    watch(
      () => selected.value,
      () => {
        if (selected.value.length == 0) {
          selectAll.value = 0;
        } else if (selected.value.length == companies.value.length) {
          selectAll.value = 1;
        } else {
          selectAll.value = -1;
        }
      }
    );
    watch(
      () => router.currentRoute.value.params,
      () => {
        selection.value = {};
        // Класс добавляется таким образом, потому что если это делать через св-во classes элемента таблицы GS1TableFlat, то данные строки мигают при изменении класса
        document.querySelector('.info-search-form .gs1-table-flat__table-tr.opened')?.classList.remove('opened');

        if (typeof router.currentRoute.value.params.company !== 'undefined' && router.currentRoute.value.params.company) {
          document.querySelector('.gs1-table-flat__table-tr.gln-' + router.currentRoute.value.params.company)?.classList.add('opened');
        }
      },
      {
        deep: true,
      }
    );

    onMounted(() => {
      store.dispatch('auth/getUser');
    });

    return {
      page,
      perPage,
      searchString,
      resultViewType,
      user,
      allCompanies,
      companies,
      showCompanyResults,
      loading,
      selection,
      companiesToExport,
      showGsTemplate,
      loader,
      activeInfo,
      infoModal,
      selected,
      selectAll,
      currentValue,
      curentActiveVField,
      onCloseGsTemplate,
      openCompany,
      exportCompanies,
      setResultViewType,
      uncheckAll,
      companyKeys,
      reset,
      showLoader,
      hideLoader,
      tableFields,
      branchCount,
    };
  },
});
</script>

<style lang="scss">
.company-search__branch-count {
  position: relative;
  height: auto;
  margin-bottom: var(--margin-x3);
}
.info-search-form__right-container {
  .company-search__bottom-items-selector {
    .gs1-element.gs1-btn.accent {
      margin-left: var(--margin-x2);
    }
  }
}
</style>
