<template lang="html">
  <div v-if="company" :class="classes">
    <GS1Tabs v-if="queryKey == 'gln' && !company.noInfo && activeStatus">
      <GS1TabsItem :active="activeTab == 'main'" @click="activeTab = 'main'">О GLN</GS1TabsItem>
      <GS1TabsItem :active="activeTab == 'license'" @click="activeTab = 'license'">
        О владельце
        {{ keyName }}
      </GS1TabsItem>
    </GS1Tabs>
    <div v-if="activeTab == 'main' && !company.noInfo" class="company-result__main">
      <GS1AlertBlockInfo v-if="company?.glnRecordStatus == 'DISCONTINUED'" warning icon="info-circle">
        <template #title>
          <span>Внимание!</span>
        </template>
        GLN заблокирован владельцем. Точность и актуальность данных не гарантируется.
      </GS1AlertBlockInfo>
      <div v-for="(glnRecord, i) in company.glnRecords" :key="i" class="company-result__record">
        <ResultBlock>
          <template #title>{{ glnRecord.glnTypeName }}</template>
          <template #default>
            <div v-if="glnRecord.name" class="company-result__name">{{ glnRecord.name }}</div>
            <div v-if="glnRecord.glnRecordStatus == 'ACTIVE' && glnRecord.glnType == 'LEGAL_ENTITY'" class="company-result__status">
              <GS1Icon small icon="success"></GS1Icon>
              Это действующее юр. лицо
            </div>
            <div class="company-result__info">
              <div class="company-result__info__fields">
                <div class="company-result__info__field">
                  <div class="company-result__info__field__label">GLN</div>
                  <div class="company-result__info__field__value">
                    {{ glnRecord.gln ? glnRecord.gln : 'Нет данных' }}
                  </div>
                </div>
                <div class="company-result__info__field">
                  <div class="company-result__info__field__label">Тип GLN</div>
                  <div class="company-result__info__field__value">
                    {{ glnRecord.glnTypeName ? glnRecord.glnTypeName : 'Нет данных' }}
                  </div>
                </div>
                <div class="company-result__info__field">
                  <div class="company-result__info__field__label">Наименование</div>
                  <div class="company-result__info__field__value">
                    {{ glnRecord.name ? glnRecord.name : 'Нет данных' }}
                  </div>
                </div>
                <div class="company-result__info__field">
                  <div class="company-result__info__field__label">Роль организации</div>
                  <div class="company-result__info__field__value">
                    {{ glnRecord.roles ? glnRecord.roles.join(', ') : 'Нет данных' }}
                  </div>
                </div>
                <div class="company-result__info__field">
                  <div class="company-result__info__field__label">Связанная организация</div>
                  <div class="company-result__info__field__value">
                    {{ glnRecord.parent ? glnRecord.parent : 'Нет данных' }}
                  </div>
                </div>
                <div v-if="!['MOBILE_PHYSICAL_LOCATION', 'DIGITAL_LOCATION'].includes(glnRecord.glnType)" class="company-result__info__field">
                  <div class="company-result__info__field__label">Адрес</div>
                  <div class="company-result__info__field__value">
                    <template v-if="glnRecord.address?.length > 0">
                      <template v-for="(address, ai) in glnRecord.address" :key="ai"> {{ address.fullAddress }} <br /> </template>
                    </template>
                    <template v-else>Нет данных</template>
                  </div>
                </div>
                <div v-if="glnRecord.glnType == 'DIGITAL_LOCATION'" class="company-result__info__field">
                  <div class="company-result__info__field__label">Адрес</div>
                  <div class="company-result__info__field__value">
                    {{ glnRecord.digitalAddress ? glnRecord.digitalAddress : 'Нет данных' }}
                  </div>
                </div>
                <div v-if="glnRecord.gln.startsWith('46')" class="company-result__info__field">
                  <div class="company-result__info__field__label">ИНН</div>
                  <div class="company-result__info__field__value">
                    {{ glnRecord.inn ? glnRecord.inn : 'Нет данных' }}
                  </div>
                </div>
              </div>
            </div>
            <div v-if="glnRecord.contactPoint?.length > 0" class="result__heading company-result__margin-bottom">Для связи</div>
            <div v-for="(contact, ci) in glnRecord.contactPoint" :key="ci" class="company-result__info">
              <div class="company-result__info__fields">
                <div class="company-result__info__field">
                  <div class="company-result__info__field__label">E-mail</div>
                  <div class="company-result__info__field__value">
                    {{ contact.email ? contact.email : 'Нет данных' }}
                  </div>
                </div>
                <div class="company-result__info__field">
                  <div class="company-result__info__field__label">Телефон</div>
                  <div class="company-result__info__field__value">
                    {{ contact.telephone ? contact.telephone : 'Нет данных' }}
                  </div>
                </div>
                <div class="company-result__info__field">
                  <div class="company-result__info__field__label">Веб-сайт</div>
                  <div class="company-result__info__field__value">
                    {{ contact.website ? contact.website : 'Нет данных' }}
                  </div>
                </div>
                <div class="company-result__info__field">
                  <div class="company-result__info__field__label">Язык</div>
                  <div class="company-result__info__field__value">
                    {{ contact.availableLanguage ? contact.availableLanguage.join(', ') : 'Нет данных' }}
                  </div>
                </div>
              </div>
            </div>
          </template>
        </ResultBlock>
      </div>
    </div>

    <div v-if="activeTab == 'license' || company.noInfo" class="company-result__license">
      <h2>О владельце {{ keyName }}</h2>
      <div class="company-result__info">
        <div class="company-result__info__fields">
          <div v-if="!activeStatus" class="company-result__info__field">
            <div class="company-result__info__field__label">Статус</div>
            <div class="company-result__info__field__value">Неактивно</div>
          </div>
          <div class="company-result__info__field">
            <div class="company-result__info__field__label">Название организации</div>
            <div class="company-result__info__field__value">
              <template v-if="queryKey == 'inn'">
                {{ company.name ? company?.name : 'Нет данных' }}
              </template>
              <template v-else>
                {{ company.gs1Licence?.licenseeName ? company?.gs1Licence?.licenseeName : 'Нет данных' }}
              </template>
            </div>
          </div>
          <div v-if="activeStatus" class="company-result__info__field">
            <div class="company-result__info__field__label">Адрес</div>
            <div class="company-result__info__field__value">
              <template v-if="queryKey == 'inn'">
                <template v-if="company.type == 'Подразделение'">
                  {{ company.branch_address ? company?.branch_address : 'Нет данных' }}
                </template>
                <template v-else>
                  {{ company.address ? company?.address : 'Нет данных' }}
                </template>
              </template>
              <template v-else>
                {{ fullAddress.length > 0 ? fullAddress : 'Нет данных' }}
              </template>
            </div>
          </div>
          <div v-if="queryKey == 'inn' && activeStatus" class="company-result__info__field">
            <div class="company-result__info__field__label">ИНН</div>
            <div class="company-result__info__field__value">
              {{ company.inn ? company?.inn : 'Нет данных' }}
            </div>
          </div>
          <div v-if="queryKey == 'inn' && activeStatus" class="company-result__info__field">
            <div class="company-result__info__field__label">Тип</div>
            <div class="company-result__info__field__value">
              {{ company.type ? company?.type : 'Нет данных' }}
            </div>
          </div>
          <div v-if="activeStatus" class="company-result__info__field">
            <div class="company-result__info__field__label">Веб-сайт</div>
            <div class="company-result__info__field__value">
              <template v-if="queryKey == 'inn'">
                {{ company.website ? company?.website : 'Нет данных' }}
              </template>
              <template v-else>
                <template v-if="licenseSites.length > 0">
                  <a v-for="(site, index) in licenseSites" :key="index" :href="site" target="_blank">
                    {{ site }}
                  </a>
                </template>
                <span v-else>Нет данных</span>
              </template>
            </div>
          </div>
          <div v-if="activeStatus" class="company-result__info__field">
            <div class="company-result__info__field__label">E-mail</div>
            <div class="company-result__info__field__value">
              <template v-if="queryKey == 'inn'">
                {{ company.email ? company?.email : 'Нет данных' }}
              </template>
              <template v-else>
                <template v-if="licenseEmails.length > 0">
                  <a v-for="(email, index) in licenseEmails" :key="index" :href="'mailto:' + email">
                    {{ email }}
                  </a>
                </template>
                <span v-else>Нет данных</span>
              </template>
            </div>
          </div>
          <div v-if="activeStatus" class="company-result__info__field">
            <div class="company-result__info__field__label">Телефон</div>
            <div class="company-result__info__field__value">
              <template v-if="queryKey == 'inn'">
                {{ company.phone ? company?.phone : 'Нет данных' }}
              </template>
              <template v-else>
                <template v-if="licensePhones.length > 0">
                  <a v-for="(phone, index) in licensePhones" :key="index" :href="'tel:' + phone">
                    {{ phone }}
                  </a>
                </template>
                <span v-else>Нет данных</span>
              </template>
            </div>
          </div>
          <div v-if="activeStatus" class="company-result__info__field">
            <div class="company-result__info__field__label">Префикс</div>
            <div class="company-result__info__field__value">
              <template v-if="queryKey == 'inn'">
                {{ company.prefix ? company?.prefix : 'Нет данных' }}
              </template>
              <template v-else>
                {{ company.gs1Licence?.licenceKey ? company?.gs1Licence?.licenceKey : 'Нет данных' }}
              </template>
            </div>
          </div>
          <div v-if="activeStatus" class="company-result__info__field">
            <div class="company-result__info__field__label">GLN</div>
            <div class="company-result__info__field__value">
              <template v-if="queryKey == 'inn'">
                {{ company.gln ? company?.gln : 'Нет данных' }}
              </template>
              <template v-else>
                {{ company.gs1Licence?.licenseeGLN ? company?.gs1Licence?.licenseeGLN : 'Нет данных' }}
              </template>
            </div>
          </div>
          <div class="company-result__info__field">
            <div class="company-result__info__field__label">Источник информации</div>
            <div class="company-result__info__field__value">
              {{ source }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, inject, computed, watch, ref, onMounted, onUnmounted } from 'vue';
import type { ComputedRef, Ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import type EventBus from '@/models/EventBus';
import ResultBlock from '@/components/ResultBlock.vue';

export default defineComponent({
  name: 'CompanyResult',
  components: { ResultBlock },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const bus: EventBus | undefined = inject('bus');

    const ViewMode: ComputedRef<string> = computed(() => store.state.ViewMode);

    const user = computed(() => store.state.auth.user);

    const company: ComputedRef<Record<string, any>> = computed(() => {
      const companyData = store.state.companySearch.company;

      if (typeof companyData == 'undefined' || typeof companyData.glnRecords == 'undefined') return store.state.companySearch.company;

      companyData.glnRecords = companyData.glnRecords.map((glnRecord: Record<string, any>) => {
        if (typeof glnRecord.address !== 'undefined') {
          glnRecord.address = glnRecord.address.map((address: Record<string, any>) => {
            const fullAddress = [];

            // Индекс
            if (typeof address.postalCode !== 'undefined' && address.postalCode.length > 0) {
              fullAddress.push(address.postalCode);
            }
            // Регион
            if (typeof address.addressRegion?.value !== 'undefined' && address.addressRegion?.value.length > 0) {
              fullAddress.push(address.addressRegion.value);
            }
            // Город
            if (typeof address.addressLocality?.value !== 'undefined' && address.addressLocality?.value.length > 0) {
              fullAddress.push(address.addressLocality.value);
            }
            // Улица
            if (typeof address.streetAddress?.value !== 'undefined' && address.streetAddress?.value.length > 0) {
              fullAddress.push(address.streetAddress.value);
            }

            address.fullAddress = fullAddress.join(', ');

            return address;
          });
        }

        let parentKey = 'parentOrganization';
        if (['FIXED_PHYSICAL_LOCATION', 'MOBILE_PHYSICAL_LOCATION', 'DIGITAL_LOCATION'].includes(glnRecord.glnType)) {
          parentKey = 'relatedOrganization';
        }
        if (typeof glnRecord[parentKey] !== 'undefined') {
          glnRecord.parent = glnRecord[parentKey].gln;
        }

        let nameKey = 'organizationName';
        switch (glnRecord.glnType) {
          case 'FUNCTION':
            nameKey = 'department';
            break;
          case 'FIXED_PHYSICAL_LOCATION':
          case 'MOBILE_PHYSICAL_LOCATION':
            nameKey = 'physicalLocationName';
            break;
          case 'DIGITAL_LOCATION':
            nameKey = 'digitalLocationName';
            break;
        }
        if (typeof glnRecord[nameKey] !== 'undefined') {
          glnRecord.name = glnRecord[nameKey][0].value;
        }

        return glnRecord;
      });

      return companyData;
    });

    const companies = computed(() => store.state.companySearch.companies);

    const licenseSites: ComputedRef<string[]> = computed(() => {
      const sites: string[] = [];

      if (typeof company.value.gs1Licence?.contactPoint !== 'undefined') {
        company.value.gs1Licence?.contactPoint.forEach((contact: Record<string, string>) => {
          if (!sites.includes(contact.website) && typeof contact.website !== 'undefined' && contact.website.length > 0 && sites.length < 3)
            sites.push(contact.website);
        });
      }

      return sites;
    });

    const licensePhones: ComputedRef<string[]> = computed(() => {
      const phones: string[] = [];

      if (typeof company.value.gs1Licence?.contactPoint !== 'undefined') {
        company.value.gs1Licence?.contactPoint.forEach((contact: Record<string, string>) => {
          if (!phones.includes(contact.telephone) && typeof contact.telephone !== 'undefined' && contact.telephone.length > 0 && phones.length < 3)
            phones.push(contact.telephone);
        });
      }

      return phones;
    });

    const licenseEmails: ComputedRef<string[]> = computed(() => {
      const emails: string[] = [];

      if (typeof company.value.gs1Licence?.contactPoint !== 'undefined') {
        company.value.gs1Licence?.contactPoint.forEach((contact: Record<string, string>) => {
          if (!emails.includes(contact.email) && typeof contact.email !== 'undefined' && contact.email.length > 0 && emails.length < 3)
            emails.push(contact.email);
        });
      }

      return emails;
    });

    const queryKey: ComputedRef<string | string[]> = computed(() => {
      switch (route.name) {
        case 'company-search-by-gln-result':
          return 'gln';
        case 'company-search-by-inn-result':
          return 'inn';
        case 'company-search-by-name-result':
          return 'name';
        case 'company-search-by-key-result':
          return route.params.key;
        default:
          return '';
      }
    });
    const keyName: ComputedRef<string> = computed(() => {
      switch (queryKey.value as string) {
        case 'inn':
          return 'ИНН';
        case 'gln':
        case 'name':
          return 'префикса';
        default:
          return (queryKey.value as string).toUpperCase();
      }
    });

    /**
     * Активность
     */
    const activeStatus: ComputedRef<boolean> = computed(() => {
      switch (queryKey.value) {
        case 'inn':
          return company.value.status !== 'INACTIVE' || user.value.pro;
        default:
          if (typeof company.value?.glnRecordStatus !== 'undefined') {
            return company.value.glnRecordStatus !== 'INACTIVE' || user.value.pro;
          }
          if (typeof company.value?.gs1Licence?.licenceStatus !== 'undefined') {
            return company.value?.gs1Licence?.licenceStatus !== 'INACTIVE' || user.value.pro;
          }

          return true;
      }
    });

    /**
     * Источник информации
     */
    const source: ComputedRef<string> = computed(() => {
      switch (queryKey.value) {
        case 'inn':
          return company.value?.source || 'Нет данных';
        default:
          return company.value?.gs1Licence?.licensingMO?.moName || 'Нет данных';
      }
    });

    const activeTab: Ref<string> = ref('license');

    const queryKeyValue: ComputedRef<string | string[]> = computed(() => route.params.keyValue);

    /**
     * Полный адрес предприятия
     */
    const fullAddress: ComputedRef<string> = computed(() => {
      const fullAddress = [];

      // Индекс
      if (typeof company.value.gs1Licence?.address?.postalCode !== 'undefined' && company.value.gs1Licence?.address?.postalCode.length > 0) {
        fullAddress.push(company.value.gs1Licence?.address.postalCode);
      }
      // Регион
      if (
        typeof company.value.gs1Licence?.address?.addressRegion?.value !== 'undefined' &&
        company.value.gs1Licence?.address?.addressRegion?.value.length > 0
      ) {
        fullAddress.push(company.value.gs1Licence?.address.addressRegion.value);
      }
      // Город
      if (
        typeof company.value.gs1Licence?.address?.addressLocality?.value !== 'undefined' &&
        company.value.gs1Licence?.address?.addressLocality?.value.length > 0
      ) {
        fullAddress.push(company.value.gs1Licence?.address.addressLocality.value);
      }
      // Улица
      if (
        typeof company.value.gs1Licence?.address?.streetAddress?.value !== 'undefined' &&
        company.value.gs1Licence?.address?.streetAddress?.value.length > 0
      ) {
        fullAddress.push(company.value.gs1Licence?.address.streetAddress.value);
      }

      return fullAddress.join(', ');
    });

    const classes: ComputedRef<{ [key: string]: boolean }> = computed((): { [key: string]: boolean } => {
      return {
        'company-result__container': true,
        ['mode-' + ViewMode.value]: true,
      };
    });

    const onCloseSidePanel = () => {
      switch (queryKey.value) {
        case 'inn':
        case 'gln':
        case 'name':
          router.push({ name: 'company-search-by-' + queryKey.value });
          break;
        default:
          router.push({ name: 'company-search-by-key' });
      }
    };

    const autoCloseSidePanel = () => {
      if (!companies?.value?.length) onCloseSidePanel();
      return;
    };
    autoCloseSidePanel();

    const getCompany = () => {
      let company = { gs1Licence: {} };
      if (queryKey.value == 'name') {
        company.gs1Licence = companies.value[queryKeyValue.value as string];
      } else if (queryKey.value == 'inn') {
        company = companies.value[queryKeyValue.value as string];
      } else {
        company = companies.value.find((company: any) => company[queryKey.value as string] == queryKeyValue.value);
      }

      store.commit('companySearch/setCompany', company);

      if (queryKey.value == 'gln' && activeStatus.value) {
        activeTab.value = 'main';
      } else {
        activeTab.value = 'license';
      }
    };

    onMounted(() => {
      // Слушатель события клика на кнопке закрытия правой панели
      if (bus) bus.on('close-sidepanel', onCloseSidePanel);

      getCompany();
    });

    onUnmounted(() => {
      if (bus) bus.off('close-sidepanel', onCloseSidePanel);
    });

    watch(queryKeyValue, () => getCompany());

    return {
      queryKeyValue,
      queryKey,
      activeTab,
      company,
      fullAddress,
      licenseSites,
      licensePhones,
      licenseEmails,
      classes,
      route,
      keyName,
      activeStatus,
      source,
    };
  },
});
</script>
<style lang="scss">
.company-result__container {
  .gs1-element.gs1-tabs-item {
    flex: 1 1;
  }

  &.mode-dark {
    .company-result__info {
      border-color: var(--color-main-22);
      h2 {
        border-color: var(--color-main-22);
      }

      .company-result__info__field {
        color: var(--color-main-f);
        &:hover {
          background-color: var(--color-additional-17);
          .company-result__info__field__value {
            color: var(--color-main-f);
          }
        }
      }
    }
    .company-result__note {
      color: var(--color-main-4);
      .company-result__note__last-update {
        color: var(--color-main-4);
      }
    }
    .placeholder__container {
      background-color: var(--color-main-27);
      border: 1px solid var(--color-main-22);
      .company-result__image {
        width: 48px;
        height: 48px;
      }
    }
    .company-result__name,
    .result__heading,
    .company-result__status {
      color: var(--color-main-f);
    }
    .result__heading .gs1-btn-icon:hover {
      background-color: var(--color-main-22);
    }
  }
  .company-result__name,
  .company-result__status {
    font-size: 13px;
    margin: var(--margin-x3) 0;
    color: var(--color-main-3);
  }
  .company-result__margin-bottom {
    margin-bottom: var(--margin-x3);
  }
  .company-result__status {
    display: flex;
    .gs1-icon {
      margin-right: var(--margin-x2);
      .gs1-icon__icon {
        background-color: var(--color-main-7) !important;
      }
    }
  }

  .result__block_wrapper {
    > .result__heading {
      margin-bottom: 0;
    }
  }
  .company-result__image-container {
    height: 160px;
    width: 160px;
    margin: var(--margin-x3) 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-main-f);
    border-radius: var(--border-radius-x22);
    .company-result__image {
      max-width: 100%;
      max-height: 100%;
    }
  }
  .placeholder__container {
    background-color: var(--color-main-f);
    border: 1px solid var(--color-main-10);
    .company-result__image {
      width: 48px;
      height: 48px;
    }
  }
  .company-result__info {
    border: var(--border-thin) solid var(--color-main-10);
    border-radius: var(--border-radius-x2);
    + .company-result__info {
      margin-top: var(--margin-x3);
    }
    h2 {
      font-weight: 500;
      font-size: 14px;
      padding: var(--padding-x3);
      border-bottom: var(--border-thin) solid var(--color-main-10);
    }
    .company-result__info__fields {
      padding: var(--padding-x2);
    }
    .company-result__info__field {
      display: flex;
      align-items: center;
      padding: var(--padding-x2);
      border-radius: var(--border-radius-x1);
      background-color: transparent;
      transition: background-color var(--transition-super-fast-smooth);
      color: var(--color-main-3);
      cursor: pointer;
      &:hover {
        background-color: var(--color-main-10);
        .company-result__info__field__value {
          color: var(--color-main-2);
        }
      }
      + .company-result__info__field {
        margin-top: var(--margin-x2);
      }
      .company-result__info__field__label {
        flex: 0 0 40%;
        width: 40%;
        font-weight: 500;
      }
      .company-result__info__field__value {
        font-size: 13px;
        flex: 0 0 60%;
        width: 60%;
        a {
          color: inherit;
          text-decoration: underline;
          display: block;
          word-wrap: break-word;
          + a {
            margin-top: var(--margin-x2);
          }
        }
        > span {
          display: block;
        }
      }
    }
  }
  .company-result__note {
    font-size: 13px;
    display: flex;
    flex-direction: column;
    margin-top: var(--margin-x25);
    .company-result__note__last-update {
      font-size: 12px;
    }
    span + span {
      margin-top: var(--margin-x25);
    }
  }

  .company-result__license {
    h2 {
      font-weight: 600;
      font-size: 13px;
      margin: 0 0 var(--margin-x3) 0;
    }
  }
  .gs1-tabs + .company-result__license h2 {
    margin-top: var(--margin-x3);
  }
  .company-result__record:first-child {
    .result__heading {
      .gs1-btn-icon {
        display: none;
      }
    }
    .result__block {
      height: auto !important;
    }
  }
}
</style>
